<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Departments</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right">
							<b-button v-b-modal.department-modal variant="primary" class="btn-sm">Add Department</b-button>
						</div>
					</template>
					<template v-slot:body>
						<!----- Listing length and search starts ----->
						<div class="row listing-length-row">
							<div class="col-sm-12 col-md-6">
								<div class="listing-length" >
									<label
										>Show
										<select class="form-control" v-model="listing_length" @change="fetchDepartments()">
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
										</select>
										entries
									</label>
								</div>								
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="div-search">
									<label>
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchDepartments()" />
									</label>
								</div>							
								
							</div>
						</div>
						<!----- Listing length and search ends ----->

						<!--- Listing starts ----->
						<div class="table-responsive">
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th width="10%">Department Name</th>			
										<th width="15%">Created on</th>
										<th width="10%">Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(departments.length)">
									<tr v-for="department in departments" :key="department.id">
										<td>{{ department.department }}</td>
										<td>{{ department.created_at | formatDateTime }}</td>
										<td>
											<b-button variant="primary" class="btn btn-sm mt-2 mr-1" @click="editDepartment(department)">Edit</b-button>
											<b-button variant="danger" class="btn mt-2 mr-1 btn-sm" @click="deactivateDepartment(department)" v-if="department.status == 1" >Unpublish</b-button>
											<b-button variant="success" class="btn mt-2 mr-1 btn-sm" @click="activateDepartment(department)" v-else>Publish</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3" class="text-center">No data</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- Listing ends -->
						<!---- modal starts ---->
						<b-modal ref="department-modal" id="department-modal" :title="modal_title" hide-footer @hidden="clearForm()">
							<b-form @submit.prevent="addDepartment">								
								<b-col md="12" class="form-group">
									<label class="control-label">Department Name<span class="required">*</span></label>
									<b-form-input v-model="department.department_name" v-bind:class="{ 'is-invalid': Boolean(errors.department_name) }"></b-form-input>
									<div v-for="(val, index) in errors.department_name" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>
								
								
								<b-col md="12">
									<b-button type="submit" class="mr-3" variant="primary">{{ button_title }}</b-button>
									<b-button class="" variant="light" @click="hideModal('department-modal')">Close</b-button>
								</b-col>
							</b-form>
						</b-modal>
						<!---- modal ends ----->
						<!----pagination starts --->
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
									<a class="page-link" href="#" @click.prevent="fetchDepartments(page.url + '&limit=' + listing_length + '&keyword=' + keyword)" tabindex="-1">
										<span v-if="index == 0">Prev</span>
										<span v-else-if="index == pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<!----pagination ends --->
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
	name: "Departments",
	data() {
		return {
			departments: [],
			department: {
				id: null,
			   department_name: "",
			   
			},
			
			modal_title: "Add Department",
			button_title: "Save",
			pagination: {},
			edit: false,
			department_status: {
				id: "",
				status: "",
			},
			keyword: "",
			showsearch: false,
			listing_length: 10,
			status: '',
			token: "",
			errors: {},
			activate: false,
         	deactivate: false,

         	active_page_url: null,
		};
	},
	mounted() {
			
	},

	created() {
		this.fetchDepartments();
	},

		methods: {
		    
		    getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

			fetchDepartments(page_url = null) 
			{
				let url = "/api/departments/index?limit=" + this.listing_length + "&keyword=" + this.keyword;
				page_url = page_url || url;
				this.active_page_url = page_url;   
				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.departments = responseData.data;
						this.pagination = responseData.links;
					})
					.catch((err) => console.log(err));
			},

			showModal(modal) {
				this.$refs[modal].show();
			},

			hideModal(modal) {
				this.$refs[modal].hide();
			},
            
            addDepartment() {
				this.getToken();
				let formData = new FormData();
				formData.append("department_name", this.department.department_name);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/departments/add";
					this.active_page_url = null;
				} else {
					url = "/api/departments/update";
					formData.append("department_id", this.department.id);
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.hideModal("department-modal");
						this.fetchDepartments(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);
						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			editDepartment(department) {	
			    this.edit = true;
				this.department.id = department.id;
				this.department.department_name = department.department;
				this.errors = {};			
				this.modal_title = "Edit Department";
				this.button_title = "Update";
				this.showModal("department-modal");
			},
			clearForm() {				
				
				this.modal_title = "Add Department";
				this.button_title = "Add";
				this.edit = false;
				this.department.department_name = "";
			},
			
			activateDepartment(department) {
				this.activate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to publish this department?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		        .then(value => {
		            this.activate = value
		            if(this.activate){
						this.department_status.id = department.id;
						this.department_status.status = "1";
						this.updateDepartmentStatus();
					}
		        })
		         .catch(err => {
		            console.log(err);
		        })
			},
			deactivateDepartment(department) {
				this.deactivate = false;
		        this.$bvModal.msgBoxConfirm('Are you sure you want to unpublish this department?', {
		            title: 'Confirm',
		            size: 'sm',
		            buttonSize: 'sm',
		            okVariant: 'danger',
		            okTitle: 'Yes',
		            cancelTitle: 'No',
		            footerClass: 'p-2',
		            hideHeaderClose: false,
		            centered: true
		        })
		         .then(value => {
		            this.deactivate = value
		            if(this.deactivate){
						this.department_status.id = department.id;
						this.department_status.status = "0";
						this.updateDepartmentStatus();
					}
         		})
         		.catch(err => {
           			console.log(err);
         		})
			},
			updateDepartmentStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/departments/status?department_id=" + this.department_status.id + "&status=" + this.department_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.fetchDepartments(this.active_page_url);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},
		},	
};
</script>
